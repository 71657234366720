import Vue from 'vue'
import VueRouter from 'vue-router'
import { client } from '@/domainConfig'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Home,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (
          user.role == 'CEO'
          || user.role == 'Admin'
          || user.role == 'Manager'
          || user.role == 'TeamLeader'
          || user.role == 'Deliverer'
          || user.role == 'StockManager'
          || user.role == 'ExecutiveAssitant'
          || user.role == 'Receptionist'
          || user.role == 'Designer'
          || user.role == 'Accountant'
          || user.role == 'HR'
        ) {
          next();
        }
        if (user.role == 'Sewer' || user.role == 'Trainee' || user.role == 'PatternMaker' || user.role == 'StockManagerMaterialVariant') {
          next('/inventory-reporting');
        }
        if (user.role == 'StockManager') {
          next('/orders-overview/all');
        }
        if (user.role == 'StockManagerMaterialAndProduct') {
          next('/status-in-stock');
        }
        if (user.role == 'OnlineSales') {
          next('/create-order');
        }
        if (user.role == 'Designer' && client.clientName !== 'WaloneFashion') {
          next('/create-order');
        }
        if (user.role == 'StockManagerMaterialVariant') {
          next('/inventory');
        }
        if (user.role == 'SalesPerson') {
          next('/create-order');
        }
        if (user.role == 'TeamLeaderPattern') {
          next('/orders-management');
        }
        // if (user.role == 'Accountant') {
        //   next('/users');
        // }
        if (user.role == 'HRFinance') {
          next('/teams');
        }
        if (user.role == 'Security' || user.role == 'Chauffer' || user.role == 'CleaningPerson' || user.role == 'Deliverer') {
          next('/info');
        }
        if (user.role == 'StockManagerWarehousProductions') {
          next('/order-item-location');
        }
      }
    },
  },
  {
    path: '/pdf-preview',
    name: 'Pdf Preview',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/PDFPreview.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      // const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if

        next();
      }
    },
  },
  {
    path: '/analytics',
    name: 'Analytics',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/Analytics.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (user.role == 'CEO'
        || user.role == 'Admin'
        || user.role == 'Manager'
        || user.role == 'TeamLeader'
        || user.role == 'Deliverer'
        || user.role == 'StockManager'
        || user.role == 'ExecutiveAssitant') {
          next();
        } else {
          // eslint-disable-next-line no-lonely-if
          if (user.role == 'TeamLeader') {
            next('/');
          }
          // eslint-disable-next-line no-lonely-if

          if (user.role == 'Sewer') {
            next('/inventory-reporting');
          }
          if (user.role == 'Trainee') {
            next('/inventory-reporting');
          }
          if (user.role == 'Deliverer') {
            next('/');
          }
        }
      }
    },
  },
  {
    path: '/inventory',
    name: 'Inventory management',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/Inventory.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (user.token && (user.role == 'CEO'
         || user.role == 'Admin'
         || user.role == 'Manager'
         || user.role == 'StockManager'
         || user.role == 'StockManagerMaterialVariant'
         || user.role == 'StockManagerMaterialAndProduct'
         || user.role == 'Designer'
         || user.role == 'StockManagerWarehousProductions')) {
          next();
        } else {
          // eslint-disable-next-line no-lonely-if
          if (user.role == 'TeamLeader') {
            next('/');
          }
          // eslint-disable-next-line no-lonely-if

          if (user.role == 'Sewer') {
            next('/inventory-reporting');
          }
          if (user.role == 'Trainee') {
            next('/inventory-reporting');
          }
          if (user.role == 'Deliverer') {
            next('/');
          }
        }
      }
    },
  },
  {
    path: '/employee-feedback',
    name: 'Feedback',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/EmployeeFeedbacks.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (
          user.token
          && (
            user.role == 'TeamLeader'
            || user.role == 'Sewer'
            || user.role == 'Trainee'
            || user.role == 'PatternMaker'
            || user.role == 'SalesPerson'
            || user.role == 'Receptionist'
            || user.role == 'StockManagerMaterialVariant'
            || user.role == 'StockManager')
        ) {
          next('/');
        } else {
          next()
        }
      }
    },
  },
  {
    path: '/inventory-reporting',
    name: 'Inventory Reporting',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/InventoryReporting.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (
          user.token
          && (user.role == 'CEO'
            || user.role == 'Admin'
            || user.role == 'Manager'
            || user.role == 'TeamLeader'
            || user.role == 'Sewer'
            || user.role == 'Trainee'
            || user.role == 'PatternMaker'
            || user.role == 'SalesPerson'
            || user.role == 'Receptionist'
            || user.role == 'StockManagerMaterialVariant'
            || user.role == 'StockManager'
            || user.role == 'ExecutiveAssitant'
            || user.role == 'StockManagerMaterialAndProduct'
            || user.role == 'Designer'
            || user.role == 'StockManagerWarehousProductions')
        ) {
          next();
        } else {
          // eslint-disable-next-line no-lonely-if
          if (user.role == 'Deliverer') {
            next('/');
          }
        }
      }
    },
  },
  {
    path: '/inventory-overview',
    name: 'Inventory Overview',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/MaterialVariant.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (
          user.token
          && (user.role == 'CEO'
            || user.role == 'Admin'
            || user.role == 'Manager'
            || user.role == 'TeamLeader'
            || user.role == 'Sewer'
            || user.role == 'Trainee'
            || user.role == 'SalesPerson'
            || user.role == 'OnlineSales'
            || user.role == 'Designer'
            || user.role == 'PatternMaker'
            || user.role == 'StockManagerMaterialVariant'
            || user.role == 'StockManager'
            || user.role == 'ExecutiveAssitant'
            || user.role == 'StockManagerMaterialAndProduct'
            || user.role == 'StockManagerWarehousProductions'
            || user.role == 'Accountant'
            || user.role == 'HR')
        ) {
          next();
        } else {
          // eslint-disable-next-line no-lonely-if
          if (user.role == 'Deliverer') {
            next('/');
          }
        }
      }
    },
  },
  {
    path: '/material-colors',
    name: 'Material Colors',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('@/views/MaterialColors.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (
          user.token
          && (user.role == 'CEO'
            || user.role == 'Admin'
            || user.role == 'Manager'
            || user.role == 'TeamLeader'
            || user.role == 'Sewer'
            || user.role == 'Trainee'
            || user.role == 'PatternMaker'
            || user.role == 'StockManagerMaterialVariant'
            || user.role == 'StockManager'
            || user.role == 'Designer'
            || user.role == 'ExecutiveAssitant'
            || user.role == 'StockManagerMaterialAndProduct'
            || user.role == 'StockManagerWarehousProductions')
        ) {
          next();
        } else {
          // eslint-disable-next-line no-lonely-if
          if (user.role == 'Deliverer') {
            next('/');
          }
        }
      }
    },
  },
  {
    path: '/material-variant-location',
    name: 'Material variant warehouse',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('@/views/MaterialVariantLocation.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (
          user.token
          && (user.role == 'CEO'
            || user.role == 'Admin'
            || user.role == 'Manager'
            || user.role == 'TeamLeader'
            || user.role == 'Sewer'
            || user.role == 'Trainee'
            || user.role == 'PatternMaker'
            || user.role == 'StockManagerMaterialVariant'
            || user.role == 'StockManager'
            || user.role == 'SalesPerson'
            || user.role == 'Designer'
            || user.role == 'OnlineSales'
            || user.role == 'ExecutiveAssitant'
            || user.role == 'Receptionist'
            || user.role == 'StockManagerMaterialAndProduct'
            || user.role == 'StockManagerWarehousProductions')
        ) {
          next();
        } else {
          // eslint-disable-next-line no-lonely-if
          if (user.role == 'Deliverer') {
            next('/info');
          }
        }
      }
    },
  },
  {
    path: '/create-order',
    name: 'Create order in store',
    meta: {
      requiresAuth: true,
      keepAlive: true,
    },
    component: () => {
      return import('../views/CreateOrder.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (user.token && (user.role == 'CEO' || user.role == 'Admin' || user.role == 'Manager'
         || user.role == 'SalesPerson' || user.role == 'Receptionist' || user.role == 'OnlineSales'
          || user.role == 'Designer' || user.role == 'ExecutiveAssitant')) {
          next();
        } else {
          // eslint-disable-next-line no-lonely-if
          if (user.role == 'TeamLeader') {
            next('/');
          }
          // eslint-disable-next-line no-lonely-if

          if (user.role == 'Sewer') {
            next('/inventory-reporting');
          }
          if (user.role == 'Trainee') {
            next('/inventory-reporting');
          }
          if (user.role == 'Deliverer') {
            next('/');
          }
          if (user.role == 'SalesPerson') {
            next('/create-order');
          }
          if (user.role == 'Receptionist') {
            next('/create-order');
          }
        }
      }
    },
  },
  {
    path: '/sell-products',
    name: 'Sell products from stock',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/SellProductsFromStock.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (user.token && (user.role == 'CEO' || user.role == 'Admin' || user.role == 'Manager'
         || user.role == 'SalesPerson' || user.role == 'Receptionist' || user.role == 'OnlineSales'
          || user.role == 'Designer' || user.role == 'ExecutiveAssitant')) {
          next();
        } else {
          // eslint-disable-next-line no-lonely-if
          if (user.role == 'TeamLeader') {
            next('/');
          }
          // eslint-disable-next-line no-lonely-if

          if (user.role == 'Sewer') {
            next('/inventory-reporting');
          }
          if (user.role == 'Trainee') {
            next('/inventory-reporting');
          }
          if (user.role == 'Deliverer') {
            next('/');
          }
          if (user.role == 'SalesPerson') {
            next('/create-order');
          }
          if (user.role == 'Receptionist') {
            next('/create-order');
          }
        }
      }
    },
  },
  {
    path: '/create-order-nominative',
    name: 'Create fabric order',
    meta: {
      requiresAuth: true,
      keepAlive: true,
    },
    component: () => {
      return import('../views/CreateOrderNominativ.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (user.token && (user.role == 'CEO' || user.role == 'Admin' || user.role == 'Manager'
         || user.role == 'SalesPerson' || user.role == 'Receptionist' || user.role == 'OnlineSales'
          || user.role == 'Designer' || user.role == 'ExecutiveAssitant')) {
          next();
        } else {
          // eslint-disable-next-line no-lonely-if
          if (user.role == 'TeamLeader') {
            next('/');
          }
          // eslint-disable-next-line no-lonely-if

          if (user.role == 'Sewer') {
            next('/inventory-reporting');
          }
          if (user.role == 'Trainee') {
            next('/inventory-reporting');
          }
          if (user.role == 'Deliverer') {
            next('/');
          }
          if (user.role == 'SalesPerson') {
            next('/create-order');
          }
          if (user.role == 'Receptionist') {
            next('/create-order');
          }
        }
      }
    },
  },
  {
    path: '/orders-nominative',
    name: 'New fabric orders',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/OrderManagementNominative.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (user.token && (user.role == 'CEO' || user.role == 'Admin' || user.role == 'Manager'
         || user.role == 'SalesPerson' || user.role == 'Receptionist' || user.role == 'OnlineSales'
          || user.role == 'Designer' || user.role == 'ExecutiveAssitant')) {
          next();
        } else {
          // eslint-disable-next-line no-lonely-if
          if (user.role == 'TeamLeader') {
            next('/');
          }
          // eslint-disable-next-line no-lonely-if

          if (user.role == 'Sewer') {
            next('/inventory-reporting');
          }
          if (user.role == 'Trainee') {
            next('/inventory-reporting');
          }
          if (user.role == 'Deliverer') {
            next('/');
          }
          if (user.role == 'SalesPerson') {
            next('/create-order');
          }
          if (user.role == 'Receptionist') {
            next('/create-order');
          }
        }
      }
    },
  },
  {
    path: '/materials-overview',
    name: 'Materials overview',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/MaterialsOverview.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (user.token && (user.role == 'CEO' || user.role == 'Admin' || user.role == 'Manager'
         || user.role == 'SalesPerson' || user.role == 'Receptionist' || user.role == 'OnlineSales'
          || user.role == 'Designer' || user.role == 'ExecutiveAssitant')) {
          next();
        } else {
          // eslint-disable-next-line no-lonely-if
          if (user.role == 'TeamLeader') {
            next('/');
          }
          // eslint-disable-next-line no-lonely-if

          if (user.role == 'Sewer') {
            next('/inventory-reporting');
          }
          if (user.role == 'Trainee') {
            next('/inventory-reporting');
          }
          if (user.role == 'Deliverer') {
            next('/');
          }
          if (user.role == 'SalesPerson') {
            next('/create-order');
          }
          if (user.role == 'Receptionist') {
            next('/create-order');
          }
        }
      }
    },
  },
  {
    path: '/materials-management',
    name: 'Materials management',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/MaterialManagement.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (user.token && (user.role == 'CEO' || user.role == 'Admin' || user.role == 'Manager'
         || user.role == 'SalesPerson' || user.role == 'Receptionist' || user.role == 'OnlineSales'
          || user.role == 'Designer' || user.role == 'ExecutiveAssitant')) {
          next();
        } else {
          // eslint-disable-next-line no-lonely-if
          if (user.role == 'TeamLeader') {
            next('/');
          }
          // eslint-disable-next-line no-lonely-if

          if (user.role == 'Sewer') {
            next('/inventory-reporting');
          }
          if (user.role == 'Trainee') {
            next('/inventory-reporting');
          }
          if (user.role == 'Deliverer') {
            next('/');
          }
          if (user.role == 'SalesPerson') {
            next('/create-order');
          }
          if (user.role == 'Receptionist') {
            next('/create-order');
          }
        }
      }
    },
  },
  {
    path: '/selling-report',
    name: 'Selling report',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/SalesReportMaterial.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (user.token && (user.role == 'CEO' || user.role == 'Admin' || user.role == 'Manager'
         || user.role == 'SalesPerson' || user.role == 'Receptionist' || user.role == 'OnlineSales'
          || user.role == 'Designer' || user.role == 'ExecutiveAssitant')) {
          next();
        } else {
          // eslint-disable-next-line no-lonely-if
          if (user.role == 'TeamLeader') {
            next('/');
          }
          // eslint-disable-next-line no-lonely-if

          if (user.role == 'Sewer') {
            next('/inventory-reporting');
          }
          if (user.role == 'Trainee') {
            next('/inventory-reporting');
          }
          if (user.role == 'Deliverer') {
            next('/');
          }
          if (user.role == 'SalesPerson') {
            next('/create-order');
          }
          if (user.role == 'Receptionist') {
            next('/create-order');
          }
        }
      }
    },
  },
  {
    path: '/product-reporting',
    name: 'Product report',
    meta: {
      requiresAuth: true,
    },
    props: (route) => {
      return {
        color: route.query.color,
      }
    },
    component: () => {
      return import('../views/ProductReporting.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (
          user.token
          && (user.role == 'Admin'
            || user.role == 'CEO'
            || user.role == 'Manager'
            || user.role == 'TeamLeader'
            || user.role == 'Sewer'
            || user.role == 'Trainee'
            || user.role == 'PatternMaker'
            || user.role == 'SalesPerson'
            || user.role == 'Receptionist'
            || user.role == 'StockManager'
            || user.role == 'Designer'
            || user.role == 'Accountant'
            || user.role == 'OnlineSales'
            || user.role == 'ExecutiveAssitant'
            || user.role == 'StockManagerMaterialAndProduct'
            || user.role == 'StockManagerWarehousProductions')
        ) {
          next();
        } else {
          // eslint-disable-next-line no-lonely-if
          if (user.role == 'Deliverer') {
            next('/');
          }
        }
      }
    },
  },
  {
    path: '/product-configuration',
    name: 'Product configuration',
    meta: {
      requiresAuth: true,
    },
    props: (route) => {
      return {
        color: route.query.color,
      }
    },
    component: () => {
      return import('../views/ProductConfiguration.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (
          user.token
          && (user.role == 'Admin'
            || user.role == 'CEO'
            || user.role == 'Manager'
            || user.role == 'TeamLeader'
            || user.role == 'Sewer'
            || user.role == 'Trainee'
            || user.role == 'PatternMaker'
            || user.role == 'SalesPerson'
            || user.role == 'Receptionist'
            || user.role == 'StockManager'
            || user.role == 'Designer'
            || user.role == 'Accountant'
            || user.role == 'OnlineSales'
            || user.role == 'ExecutiveAssitant'
            || user.role == 'StockManagerMaterialAndProduct'
            || user.role == 'StockManagerWarehousProductions')
        ) {
          next();
        } else {
          // eslint-disable-next-line no-lonely-if
          if (user.role == 'Deliverer') {
            next('/');
          }
        }
      }
    },
  },
  {
    path: '/product-in-stock',
    name: 'Product in merch',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/PassiveStock.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (
          user.token
          && (user.role == 'Admin'
            || user.role == 'CEO'
            || user.role == 'Manager'
            || user.role == 'TeamLeader'
            || user.role == 'Sewer'
            || user.role == 'Trainee'
            || user.role == 'PatternMaker'
            || user.role == 'SalesPerson'
            || user.role == 'Receptionist'
            || user.role == 'StockManager'
            || user.role == 'ExecutiveAssitant'
            || user.role == 'StockManagerMaterialAndProduct'
            || user.role == 'Designer'
            || user.role == 'StockManagerWarehousProductions')
        ) {
          next();
        } else {
          // eslint-disable-next-line no-lonely-if
          if (user.role == 'Deliverer') {
            next('/');
          }
        }
      }
    },
  },
  {
    path: '/products-in-stock',
    name: 'Merch in store',
    meta: {
      requiresAuth: true,
    },
    props: (route) => {
      return {
        articleNumber: route.query.articleNumber,
        state: route.query.state,
        inStock: route.query.inStock,
      }
    },
    component: () => {
      return import('../views/PassiveStockProducts.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (
          user.token
          && (user.role == 'Admin'
            || user.role == 'CEO'
            || user.role == 'Manager'
            || user.role == 'TeamLeader'
            || user.role == 'Sewer'
            || user.role == 'Trainee'
            || user.role == 'PatternMaker'
            || user.role == 'SalesPerson'
            || user.role == 'Receptionist'
            || user.role == 'StockManager'
            || user.role == 'Designer'
            || user.role == 'OnlineSales'
            || user.role == 'ExecutiveAssitant'
            || user.role == 'StockManagerMaterialAndProduct'
            || user.role == 'StockManagerWarehousProductions')
        ) {
          next();
        } else {
          // eslint-disable-next-line no-lonely-if
          if (user.role == 'Deliverer') {
            next('/');
          }
        }
      }
    },
  },
  {
    path: '/products-stock',
    name: 'Prodcuts in stock',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/ProductsStock.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (
          user.token
          && (user.role == 'Admin'
            || user.role == 'CEO'
            || user.role == 'Manager'
            || user.role == 'TeamLeader'
            || user.role == 'Sewer'
            || user.role == 'Trainee'
            || user.role == 'PatternMaker'
            || user.role == 'SalesPerson'
            || user.role == 'Receptionist'
            || user.role == 'StockManager'
            || user.role == 'Designer'
            || user.role == 'OnlineSales'
            || user.role == 'ExecutiveAssitant'
            || user.role == 'StockManagerMaterialAndProduct'
            || user.role == 'StockManagerWarehousProductions')
        ) {
          next();
        } else {
          // eslint-disable-next-line no-lonely-if
          if (user.role == 'Deliverer') {
            next('/');
          }
        }
      }
    },
  },
  {
    path: '/internal-moving-stock',
    name: 'Internal stock moving',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/InternalMovingStocks.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (
          user.token
          && (user.role == 'Admin'
            || user.role == 'CEO'
            || user.role == 'Manager'
            || user.role == 'TeamLeader'
            || user.role == 'Sewer'
            || user.role == 'Trainee'
            || user.role == 'PatternMaker'
            || user.role == 'SalesPerson'
            || user.role == 'Receptionist'
            || user.role == 'StockManager'
            || user.role == 'Designer'
            || user.role == 'OnlineSales'
            || user.role == 'ExecutiveAssitant'
            || user.role == 'StockManagerMaterialAndProduct'
            || user.role == 'StockManagerWarehousProductions')
        ) {
          next();
        } else {
          // eslint-disable-next-line no-lonely-if
          if (user.role == 'Deliverer') {
            next('/');
          }
        }
      }
    },
  },
  {
    path: '/product-management-in-stock',
    name: 'Product Management in Store',
    meta: {
      requiresAuth: true,
    },
    props: (route) => {
      return {
        teamIdFromUrl: route.query.teamIdFromUrl,
        pagee: route.query.page,
        pageSizee: route.query.pageSize,
      }
    },
    component: () => {
      return import('../views/ProductionManagementInStore.vue');
    },
  },
  // {
  //   path: '/product-overview-in-stock',
  //   name: 'Product Overview in Store',
  //   meta: {
  //     requiresAuth: true,
  //   },
  //   component: () => {
  //     return import('../views/ProductionOverviewInStore.vue');
  //   },
  //   beforeEnter: (to, from, next) => {
  //     const keyname = JSON.parse(localStorage.getItem('vuex'));
  //     const user = keyname.authentication.loggedInUser;
  //     if (!keyname) {
  //       next('/login');
  //     } else {
  //       // eslint-disable-next-line no-lonely-if
  //       if (
  //         user.token
  //         && (user.role == 'Admin'
  //           || user.role == 'CEO'
  //           || user.role == 'Manager'
  //           || user.role == 'TeamLeader'
  //           || user.role == 'Sewer'
  //           || user.role == 'Trainee'
  //           || user.role == 'PatternMaker'
  //           || user.role == 'SalesPerson'
  //           || user.role == 'StockManager')
  //       ) {
  //         next();
  //       } else {
  //         // eslint-disable-next-line no-lonely-if
  //         if (user.role == 'Deliverer') {
  //           next('/');
  //         }
  //       }
  //     }
  //   },
  // },
  {
    path: '/status-in-stock',
    name: 'Merch production status',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/PassiveStockDashboard.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (
          user.token
          && (user.role == 'Admin'
            || user.role == 'CEO'
            || user.role == 'Manager'
            || user.role == 'TeamLeader'
            || user.role == 'Sewer'
            || user.role == 'Trainee'
            || user.role == 'PatternMaker'
            || user.role == 'SalesPerson'
            || user.role == 'Receptionist'
            || user.role == 'StockManager'
            || user.role == 'ExecutiveAssitant'
            || user.role == 'StockManagerMaterialAndProduct'
            || user.role == 'Designer'
            || user.role == 'StockManagerWarehousProductions')
        ) {
          next();
        } else {
          // eslint-disable-next-line no-lonely-if
          if (user.role == 'Deliverer') {
            next('/');
          }
        }
      }
    },
  },
  {
    path: '/orders-management',
    name: 'New Orders',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/OrdersManagement.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (user.token && (user.role == 'CEO' || user.role == 'Admin' || user.role == 'Manager' || user.role == 'Receptionist' || user.role == 'ExecutiveAssitant' || user.role == 'Designer' || user.role == 'TeamLeaderPattern')) {
          next();
        } else {
          // eslint-disable-next-line no-lonely-if
          if (user.role == 'TeamLeader') {
            next('/');
          }
          // eslint-disable-next-line no-lonely-if

          if (user.role == 'Sewer') {
            next('/inventory-reporting');
          }
          if (user.role == 'Trainee') {
            next('/inventory-reporting');
          }
          if (user.role == 'Deliverer') {
            next('/');
          }
        }
      }
    },
  },
  {
    path: '/return-process',
    name: 'Return Process',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/ReturnToStore.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (user.token && (user.role == 'CEO' || user.role == 'Admin' || user.role == 'Manager' || user.role == 'Receptionist' || user.role == 'ExecutiveAssitant' || user.role == 'Designer' || user.role == 'TeamLeaderPattern')) {
          next();
        } else {
          // eslint-disable-next-line no-lonely-if
          if (user.role == 'TeamLeader') {
            next('/');
          }
          // eslint-disable-next-line no-lonely-if

          if (user.role == 'Sewer') {
            next('/inventory-reporting');
          }
          if (user.role == 'Trainee') {
            next('/inventory-reporting');
          }
          if (user.role == 'Deliverer') {
            next('/');
          }
        }
      }
    },
  },
  {
    path: '/suppliers',
    name: 'Suppliers',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/Suppliers.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (user.token && (user.role == 'Admin' || user.role == 'CEO' || user.role == 'Manager'
         || user.role == 'StockManager' || user.role == 'StockManagerMaterialVariant'
         || user.role == 'ExecutiveAssitant' || user.role == 'StockManagerMaterialAndProduct' || user.role == 'StockManagerWarehousProductions')) {
          next();
        } else {
          // eslint-disable-next-line no-lonely-if
          if (user.role == 'TeamLeader') {
            next('/');
          }
          // eslint-disable-next-line no-lonely-if

          if (user.role == 'Sewer') {
            next('/inventory-reporting');
          }
          if (user.role == 'Trainee') {
            next('/inventory-reporting');
          }
          if (user.role == 'Deliverer') {
            next('/');
          }
        }
      }
    },
  },
  {
    path: '/history-production',
    name: 'history',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/HistoryOfProduction.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (user.token && (user.role == 'Admin' || user.role == 'CEO' || user.role == 'Manager')) {
          next();
        } else {
          // eslint-disable-next-line no-lonely-if
          if (user.role == 'TeamLeader') {
            next('/');
          }
          // eslint-disable-next-line no-lonely-if

          if (user.role == 'Sewer') {
            next('/inventory-reporting');
          }
          if (user.role == 'Trainee') {
            next('/inventory-reporting');
          }
          if (user.role == 'Deliverer') {
            next('/');
          }
        }
      }
    },
  },
  {
    path: '/clients',
    name: 'Clients/Leads',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/ClientPage.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (user.token && (user.role == 'Admin' || user.role == 'CEO' || user.role == 'Manager' || user.role == 'Receptionist' || user.role == 'SalesPerson' || user.role == 'Designer' || user.role == 'OnlineSales' || user.role == 'Accountant' || user.role == 'HR')) {
          next();
        } else {
          // eslint-disable-next-line no-lonely-if
          if (user.role == 'TeamLeader') {
            next('/');
          }
          // eslint-disable-next-line no-lonely-if

          if (user.role == 'Sewer') {
            next('/inventory-reporting');
          }
          if (user.role == 'Trainee') {
            next('/inventory-reporting');
          }
          if (user.role == 'Deliverer') {
            next('/');
          }
        }
      }
    },
  },
  {
    path: '/clients/editClient/:id',
    name: 'edit-client',
    props: true,
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/ClientInfo.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (user.token && (user.role == 'Admin' || user.role == 'CEO' || user.role == 'Manager' || user.role == 'Receptionist')) {
          next();
        } else {
          // eslint-disable-next-line no-lonely-if
          if (user.role == 'TeamLeader') {
            next('/');
          }
          // eslint-disable-next-line no-lonely-if

          if (user.role == 'Sewer') {
            next('/inventory-reporting');
          }
          if (user.role == 'Trainee') {
            next('/inventory-reporting');
          }
          if (user.role == 'Deliverer') {
            next('/');
          }
        }
      }
    },
  },
  {
    path: '/clients/orders/:id',
    name: 'client-orders',
    props: true,
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('@/components/clients/ClientOrders.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (user.token && (user.role == 'Admin' || user.role == 'CEO' || user.role == 'Manager' || user.role == 'Receptionist')) {
          next();
        } else {
          // eslint-disable-next-line no-lonely-if
          if (user.role == 'TeamLeader') {
            next('/');
          }
          // eslint-disable-next-line no-lonely-if

          if (user.role == 'Sewer') {
            next('/inventory-reporting');
          }
          if (user.role == 'Trainee') {
            next('/inventory-reporting');
          }
          if (user.role == 'Deliverer') {
            next('/');
          }
        }
      }
    },
  },
  {
    path: '/campaign',
    name: 'Campaign',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/CampaignView.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (user.token && (user.role == 'Admin' || user.role == 'CEO' || user.role == 'Manager' || user.role == 'Receptionist' || user.role == 'SalesPerson' || user.role == 'Designer' || user.role == 'OnlineSales')) {
          next();
        } else {
          // eslint-disable-next-line no-lonely-if
          if (user.role == 'TeamLeader') {
            next('/');
          }
          // eslint-disable-next-line no-lonely-if

          if (user.role == 'Sewer') {
            next('/inventory-reporting');
          }
          if (user.role == 'Trainee') {
            next('/inventory-reporting');
          }
          if (user.role == 'Deliverer') {
            next('/');
          }
        }
      }
    },
  },
  {
    path: '/cart/history/:id',
    name: 'variant-history',
    props: true,
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../components/MaterialOrderTracking.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (user.token && (user.role == 'Admin' || user.role == 'CEO' || user.role == 'Manager' || user.role == 'Receptionist')) {
          next();
        } else {
          // eslint-disable-next-line no-lonely-if
          if (user.role == 'TeamLeader') {
            next('/');
          }
          // eslint-disable-next-line no-lonely-if

          if (user.role == 'Sewer') {
            next('/inventory-reporting');
          }
          if (user.role == 'Trainee') {
            next('/inventory-reporting');
          }
          if (user.role == 'Deliverer') {
            next('/');
          }
        }
      }
    },
  },
  {
    path: '/buy-direct',
    name: 'Direct purchase',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/PurchaseDirectSell.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if

        next();
      }
    },
  },
  {
    path: '/buy-direct-articles',
    name: 'Direct purchase',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/PurchaseDirectBuyArticles.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if

        next();
      }
    },
  },
  {
    path: '/filiales',
    name: 'Store Overview',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/Filiales.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if

        next();
      }
    },
  },
  {
    path: '/calendar',
    name: 'Vacation Plan',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/CalendarOverview.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if

        next();
      }
    },
  },
  {
    path: '/montage-overview',
    name: 'Montage Overview',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../components/MontageOverview.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if

        next();
      }
    },
  },
  {
    path: '/order-item-tracking',
    name: 'Order item tracking',
    meta: {
      requiresAuth: true,
      // forceReload: true,
    },
    props: (route) => {
      return {
        page: route.query.page,
        client: route.query.clientID,
        clientName: route.query.clientName,
        // orderer: route.query.orderer,
      }
    },
    component: () => {
      return import('../views/OrderItemTracking.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (user.token && (user.role == 'Admin' || user.role == 'CEO'
        || user.role == 'Manager' || user.role == 'StockManagerMaterialVariant'
        || user.role == 'ExecutiveAssitant'
        || user.role == 'StockManagerMaterialAndProduct'
        || user.role == 'Designer'
        || user.role == 'TeamLeaderPattern'
        )) {
          next();
        } else {
          // eslint-disable-next-line no-lonely-if
          if (user.role == 'TeamLeader') {
            next('/');
          }
          // eslint-disable-next-line no-lonely-if

          if (user.role == 'Sewer') {
            next('/inventory-reporting');
          }
          if (user.role == 'Trainee') {
            next('/inventory-reporting');
          }
          if (user.role == 'Deliverer') {
            next('/');
          }
        }
      }
    },
  },
  {
    path: '/product-in-stock-track-trace',
    name: 'Merch production tracking',
    meta: {
      requiresAuth: true,
      // forceReload: true,
    },
    props: (route) => {
      return {
        teamId: route.query.teamId,
        // orderer: route.query.orderer,
      }
    },
    component: () => {
      return import('../views/ArticleNameTracking.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (user.token && (user.role == 'Admin' || user.role == 'CEO' || user.role == 'Manager'
         || user.role == 'StockManager' || user.role == 'Receptionist'
         || user.role == 'ExecutiveAssitant' || user.role == 'StockManagerMaterialAndProduct' || user.role == 'StockManagerWarehousProductions' || user.role == 'SalesPerson')) {
          next();
        } else {
          // eslint-disable-next-line no-lonely-if
          if (user.role == 'TeamLeader') {
            next('/');
          }
          // eslint-disable-next-line no-lonely-if

          if (user.role == 'Sewer') {
            next('/inventory-reporting');
          }
          if (user.role == 'Trainee') {
            next('/inventory-reporting');
          }
          if (user.role == 'Deliverer') {
            next('/');
          }
        }
      }
    },
  },
  {
    path: '/order-item-location',
    name: 'Clients warehouse',
    meta: {
      requiresAuth: true,
      // forceReload: true,
    },
    component: () => {
      return import('../views/OrderItemLocation.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (user.token && (user.role == 'Admin' || user.role == 'CEO' || user.role == 'Manager'
         || user.role == 'Receptionist' || user.role == 'StockManager' || user.role == 'Designer'
         || user.role == 'OnlineSales' || user.role == 'ExecutiveAssitant' || user.role == 'StockManagerWarehousProductions' || user.role == 'StockManagerMaterialVariant' || user.role == 'SalesPerson')) {
          next();
        } else {
          // eslint-disable-next-line no-lonely-if
          if (user.role == 'TeamLeader') {
            next('/');
          }
          // eslint-disable-next-line no-lonely-if

          if (user.role == 'Sewer') {
            next('/inventory-reporting');
          }
          if (user.role == 'Trainee') {
            next('/inventory-reporting');
          }
          if (user.role == 'Deliverer') {
            next('/');
          }
        }
      }
    },
  },
  {
    path: '/product-in-stock-location',
    name: 'Products stock warehouse',
    meta: {
      requiresAuth: true,
      // forceReload: true,
    },
    component: () => {
      return import('../views/ProductInStockLocation.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (user.token && (user.role == 'Admin' || user.role == 'CEO' || user.role == 'Manager' || user.role == 'SalesPerson'
        || user.role == 'Receptionist' || user.role == 'TeamLeader' || user.role == 'StockManager' || user.role == 'Designer'
        || user.role == 'OnlineSales' || user.role == 'ExecutiveAssitant' || user.role == 'StockManagerMaterialAndProduct'
         || user.role == 'StockManagerMaterialVariant' || user.role == 'StockManagerWarehousProductions' || user.role == 'SalesPerson')) {
          next();
        } else {
          // eslint-disable-next-line no-lonely-if
          if (user.role == 'TeamLeader') {
            next('/');
          }
          // eslint-disable-next-line no-lonely-if

          if (user.role == 'Sewer') {
            next('/inventory-reporting');
          }
          if (user.role == 'Trainee') {
            next('/inventory-reporting');
          }
          if (user.role == 'Deliverer') {
            next('/');
          }
        }
      }
    },
  },
  {
    path: '/teams',
    name: 'Teams management',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/Teams.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (
          user.token
          && (user.role == 'Admin'
            || user.role == 'CEO'
            || user.role == 'Manager'
            || user.role == 'TeamLeader'
            || user.role == 'ExecutiveAssitant'
            || user.role == 'HRFinance'
            || user.role == 'HR'
            || user.role == 'Accountant')
        ) {
          next();
        } else {
          // eslint-disable-next-line no-lonely-if
          if (user.role == 'Sewer') {
            next('/inventory-reporting');
          }
          if (user.role == 'Trainee') {
            next('/inventory-reporting');
          }
          if (user.role == 'Deliverer') {
            next('/');
          }
        }
      }
    },
  },
  {
    path: '/users',
    name: 'Employee management',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/Users.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (user.token && (
          user.role == 'Admin'
        || user.role == 'CEO'
         || user.role == 'Manager'
         || user.role == 'Accountant'
         || user.role == 'HRFinance'
         || user.role == 'HR'
        )
        ) {
          next();
        } else {
          // eslint-disable-next-line no-lonely-if
          if (user.role == 'TeamLeader') {
            next('/');
          }
          // eslint-disable-next-line no-lonely-if

          if (user.role == 'Sewer') {
            next('/inventory-reporting');
          }
          if (user.role == 'Trainee') {
            next('/inventory-reporting');
          }
          if (user.role == 'Deliverer') {
            next('/');
          }
        }
      }
    },
  },
  {
    path: '/hr-dashboard',
    name: 'Human resources dashboard',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/HumanResourcesDashboard.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (user.token && (
          user.role == 'Admin'
        || user.role == 'CEO'
        || user.role == 'Manager'
        || user.role == 'HRFinance'
        || user.role == 'HR'
        )
        ) {
          next();
        } else {
          // eslint-disable-next-line no-lonely-if
          if (user.role == 'TeamLeader') {
            next('/');
          }
          // eslint-disable-next-line no-lonely-if

          if (user.role == 'Sewer') {
            next('/inventory-reporting');
          }
          if (user.role == 'Trainee') {
            next('/inventory-reporting');
          }
          if (user.role == 'Deliverer') {
            next('/');
          }
        }
      }
    },
  },
  {
    path: '/leave-request',
    name: 'Vacation Management',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/LeaveRequest.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      // const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        next();
      }
    },
  },
  {
    path: '/login',
    name: 'Login',
    meta: { hideNavigation: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => {
      return import(/* webpackChunkName: "about" */ '../views/About.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      let isLog = null;
      if (keyname) {
        isLog = keyname.authentication.isLoggedIn;
      }
      if (to.name == 'Login' && keyname != null && isLog) {
        next('/');
      } else {
        // eslint-disable-next-line no-lonely-if
        next();
      }
    },
  },
  {
    path: '/products',
    name: 'Products Management',
    meta: {
      requiresAuth: true,
      keepAlive: false,
    },
    component: () => {
      return import('../views/Products.vue');
    },
    beforeEnter: (to, from, next) => {
      // const token = localStorage.getItem("token");
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      // token === "" || token == null ||
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (
          user.token
          && (user.role == 'Admin'
            || user.role == 'CEO'
            || user.role == 'Manager'
            || user.role == 'Trainee'
            || user.role == 'StockManager'
            || user.role == 'TeamLeader'
            || user.role == 'Designer'
            || user.role == 'Receptionist'
            || user.role == 'OnlineSales'
            || user.role == 'StockManagerMaterialAndProduct'
            || user.role == 'StockManagerWarehousProductions')
        ) {
          next();
        } else {
          // eslint-disable-next-line no-lonely-if
          // if (user.role == 'TeamLeader') {
          //   next('/');
          // }
          // eslint-disable-next-line no-lonely-if

          if (user.role == 'Sewer') {
            next('/inventory-reporting');
          }
          if (user.role == 'Deliverer') {
            next('/');
          }
        }
      }
    },
  },
  {
    path: '/production-management',
    name: 'Product Management',
    meta: {
      requiresAuth: true,
    },
    props: true,
    component: () => {
      return import('../views/ProductManagement.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (
          user.token
          && (user.role == 'Admin'
            || user.role == 'CEO'
            || user.role == 'Manager'
            || user.role == 'Deliverer'
            || user.role == 'TeamLeader'
            || user.role == 'ExecutiveAssitant'
            || user.role == 'Receptionist'
            || user.role == 'Designer'
            || user.role == 'TeamLeaderPattern'
          )
        ) {
          next();
        } else {
          // eslint-disable-next-line no-lonely-if

          if (user.role == 'Sewer') {
            next('/inventory-reporting');
          }
          if (user.role == 'Trainee') {
            next('/inventory-reporting');
          }
        }
      }
    },
  },
  {
    path: '/orders-overview-partially',
    name: 'Orders overview partially',
    meta: {
      requiresAuth: true,
    },
    props: true,
    component: () => {
      return import('../views/OrdersOverviewPartially.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (
          user.token
          && (user.role == 'Admin'
            || user.role == 'CEO'
            || user.role == 'Manager'
            || user.role == 'TeamLeader'
            || user.role == 'StockManagerMaterialVariant'
            || user.role == 'Receptionist'
            || user.role == 'StockManager'
            || user.role == 'SalesPerson'
            || user.role == 'Designer'
            || user.role == 'Accountant'
            || user.role == 'OnlineSales'
            || user.role == 'ExecutiveAssitant'
            || user.role == 'HR'
            || user.role == 'TeamLeaderPattern'
          )
        ) {
          next();
        } else {
          // eslint-disable-next-line no-lonely-if

          if (user.role == 'Sewer') {
            next('/inventory-reporting');
          }
          if (user.role == 'Trainee') {
            next('/inventory-reporting');
          }
        }
      }
    },
  },
  {
    path: '/orders-overview/:state',
    name: 'Orders Overview',
    meta: {
      requiresAuth: true,
    },
    props: (route) => {
      return {
        state: route.params.state,
        orderToScroll: route.query.onp, // Use route.query.onp to access the query parameter
        clientName: route.query.clientName,
        clientId: route.query.clientId,
        pageNumber: route.query.pageNumber,
        // order: route.query.order,
      };
    },
    component: () => {
      return import('../views/OrdersOverview.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (
          user.token
          && (user.role == 'Admin'
            || user.role == 'CEO'
            || user.role == 'Manager'
            || user.role == 'TeamLeader'
            || user.role == 'StockManagerMaterialVariant'
            || user.role == 'Receptionist'
            || user.role == 'StockManager'
            || user.role == 'SalesPerson'
            || user.role == 'Designer'
            || user.role == 'Accountant'
            || user.role == 'OnlineSales'
            || user.role == 'ExecutiveAssitant'
            || user.role == 'HR'
            || user.role == 'TeamLeaderPattern'
          )
        ) {
          next();
        } else {
          // eslint-disable-next-line no-lonely-if

          if (user.role == 'Sewer') {
            next('/inventory-reporting');
          }
          if (user.role == 'Trainee') {
            next('/inventory-reporting');
          }
        }
      }
    },
  },

  {
    path: '/deliverer-orders-management/:state',
    name: 'Deliverer Orders Management',
    meta: {
      requiresAuth: true,
    },
    props: true,
    component: () => {
      return import('../views/DelivererOrdersManagement.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (
          user.token
          && (user.role == 'Admin'
            || user.role == 'CEO'
            || user.role == 'Manager'
            || user.role == 'TeamLeader'
            || user.role == 'Deliverer')
        ) {
          next();
        } else {
          // eslint-disable-next-line no-lonely-if

          if (user.role == 'Sewer') {
            next('/inventory-reporting');
          }
          if (user.role == 'Trainee') {
            next('/inventory-reporting');
          }
        }
      }
    },
  },
  {
    path: '/location',
    name: 'Location',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/Location.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      // const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if

        next();
      }
    },
  },
  {
    path: '/lockout',
    name: 'Lockout',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/LockoutSession.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      // const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if

        next();
      }
    },
  },
  {
    path: '/info',
    name: 'Info',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/info.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      // const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if

        next();
      }
    },
  },
  {
    path: '/selling',
    name: 'Sales report',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/SellingReportView.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      // const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if

        next();
      }
    },
  },
  {
    path: '/internal-moving',
    name: 'Internal Moving',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/InternalMovingTabs.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      // const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if

        next();
      }
    },
  },
  {
    path: '/internal-moving-materials',
    name: 'Internal moving materials',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/InternalMovingMaterials.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      // const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if

        next();
      }
    },
  },
  {
    path: '/admin',
    name: 'Admin authorise',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/AdminOrders.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (user.token && (user.role == 'CEO' || user.role == 'Admin' || user.role == 'Manager' || user.role == 'Receptionist' || user.role == 'TeamLeaderPattern')) {
          next();
        } else {
          // eslint-disable-next-line no-lonely-if
          if (user.role == 'TeamLeader') {
            next('/');
          }
          // eslint-disable-next-line no-lonely-if

          if (user.role == 'Sewer') {
            next('/inventory-reporting');
          }
          if (user.role == 'Trainee') {
            next('/inventory-reporting');
          }
          if (user.role == 'Deliverer') {
            next('/');
          }
        }
      }
    },
  },
  {
    path: '/new-orders-edit',
    name: 'Edit Orders',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/EditFabricOrders.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (user.token && (user.role == 'CEO' || user.role == 'Admin' || user.role == 'Manager' || user.role == 'TeamLeader' || user.role == 'TeamLeaderPattern')) {
          next();
        } else {
          // eslint-disable-next-line no-lonely-if

          if (user.role == 'Sewer') {
            next('/inventory-reporting');
          }
          if (user.role == 'Trainee') {
            next('/inventory-reporting');
          }
          if (user.role == 'Deliverer') {
            next('/');
          }
        }
      }
    },
  },
  {
    path: '/employee-payments',
    name: 'Salary',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/EmployeePayment.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if

        // eslint-disable-next-line no-lonely-if
        if (
          user.token
          && (
            user.role == 'TeamLeader'
            || user.role == 'Sewer'
            || user.role == 'Trainee'
            || user.role == 'PatternMaker'
            || user.role == 'SalesPerson'
            || user.role == 'Receptionist'
            || user.role == 'StockManagerMaterialVariant'
            || user.role == 'StockManager'
          )
        ) {
          next('/');
        } else if (user.role == 'Accountant' || user.role == 'Admin' || user.role == 'Manager' || user.role == 'CEO' || user.role == 'HRFinance' || user.role == 'HR') {
          next()
        }
      }
    },
  },
  {
    path: '/finance-dashboard',
    name: 'Finance Dashboard',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/FinanceDashboard.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if

        // eslint-disable-next-line no-lonely-if
        if (
          user.token
          && (
            user.role == 'TeamLeader'
            || user.role == 'Sewer'
            || user.role == 'Trainee'
            || user.role == 'PatternMaker'
            || user.role == 'SalesPerson'
            || user.role == 'Receptionist'
            || user.role == 'StockManagerMaterialVariant'
            || user.role == 'StockManager')
        ) {
          next('/');
        } else if (user.role == 'Accountant' || user.role == 'Admin' || user.role == 'Manager' || user.role == 'CEO' || user.role == 'HRFinance') {
          next()
        }
      }
    },
  },
  {
    path: '/finance-account',
    name: 'Accounts',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/FinanceAccount.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if

        // eslint-disable-next-line no-lonely-if
        if (
          user.token
          && (
            user.role == 'TeamLeader'
            || user.role == 'Sewer'
            || user.role == 'Trainee'
            || user.role == 'PatternMaker'
            || user.role == 'SalesPerson'
            || user.role == 'Receptionist'
            || user.role == 'StockManagerMaterialVariant'
            || user.role == 'StockManager')
        ) {
          next('/');
        } else if (user.role == 'Accountant' || user.role == 'Admin' || user.role == 'Manager' || user.role == 'CEO' || user.role == 'HRFinance') {
          next()
        }
      }
    },
  },
  {
    path: '/finance-report',
    name: 'Financial Report',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/FinanceReport.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if

        // eslint-disable-next-line no-lonely-if
        if (
          user.token
          && (
            user.role == 'TeamLeader'
            || user.role == 'Sewer'
            || user.role == 'Trainee'
            || user.role == 'PatternMaker'
            || user.role == 'SalesPerson'
            || user.role == 'Receptionist'
            || user.role == 'StockManagerMaterialVariant'
            || user.role == 'StockManager')
        ) {
          next('/');
        } else if (user.role == 'Accountant' || user.role == 'Admin' || user.role == 'Manager' || user.role == 'CEO' || user.role == 'HRFinance') {
          next()
        }
      }
    },
  },
  {
    path: '/bankholidays',
    name: 'Bank Holidays',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/BankHolidays.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      // const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if

        next();
      }
    },
  },
  {
    path: '/cart',
    name: 'Cart',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/MaterialCart.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      // const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if

        next();
      }
    },
  },
  {
    path: '/accountant-view',
    name: 'accountant',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../components/teams/AccountantView.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      // const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if

        next();
      }
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: {
      requiresAuth: true,
    },
    component: () => {
      return import('../views/User.vue');
    },
    beforeEnter: (to, from, next) => {
      const keyname = JSON.parse(localStorage.getItem('vuex'));
      // const user = keyname.authentication.loggedInUser;
      if (!keyname) {
        next('/login');
      } else {
        // eslint-disable-next-line no-lonely-if

        next();
      }
    },
  },
];


const router = new VueRouter({
  mode: 'history',
  routes,
})

export default router
