import * as signalR from '@microsoft/signalr';
import store from '../store/index';
import { api } from '../domainConfig';

const domain = api.stockManagementDomainNotification;

let connection = null; // This will hold the singleton connection

// Create the connection only if it's not already created
const getConnection = () => {
  if (!connection) {
    connection = new signalR.HubConnectionBuilder()
      .withAutomaticReconnect()
      .withUrl(`${domain}/hubs/notifications`, {
        accessTokenFactory: () => {
          const loggedInUser = store.state.authentication.loggedInUser;
          if (loggedInUser && loggedInUser.token) {
            return loggedInUser.token;
          }
          console.error('Bearer token is null or not present');
          return null;
        },
        transport: signalR.HttpTransportType.WebSockets,
        skipNegotiation: true,
      })
      .build();

    // Start the connection
    connection.start()
      .then(() => { return console.log('SignalR connection established') })
      .catch((err) => { return console.error('SignalR connection failed: ', err) });
  }

  return connection;
};

// Export the singleton connection
export default getConnection;
