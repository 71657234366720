import axios from 'axios';
import Swal from 'sweetalert2';
import { api } from '../../domainConfig';


/* eslint no-shadow: ["error", { "allow": ["state"] }] */

const domain = api.masterDataDomain;
const domain2 = api.stockManagementDomain;
const domain3 = api.userManagementDomain;
const domain4 = api.inventoryMenagement

const state = {
  status: false,
  customOrders: [],
  ordersGrouppedByState: [],
  ordersGrouppedByStateDashboard: [],
  currentPageForMVM: 1,
  totalPagesForMVM: 0,
  currentPageForMVP: 1,
  totalPagesForMVP: 0,
  totalPagesCogs: 0,
  totalItemsCogs: 0,
  currentPageCogs: 1,
  currentPage: 1,
  materialsVByMeter: [],
  materialsVByPart: [],
  materialsVByPackage: [],
  materialsVBySet: [],
  materialsVByPair: [],
  materialsVByVerse: [],
  materialsVByMeter_Square: [],
  sizeTypes: [],
  images: null,
  imagesProduct: null,
  imagesProductionManagement: null,
  imagesOrder: [],
  productInStockTrackAndTrace: [],
  productInStockLocationPDF: [],
  sellingReport: [],
  articleReport: [],
  sellingReportPDF: [],
  aricleNumberNameLike: [],
  orderItemStates: [],
  materialItemStates: [],
  productInStockStates: [],
  bodyMeasurement: [],
  costOfGoodsSoldPDF: [],
  dressCategory: [],
  productSize: [],
  dressCategoryByName: [],
  materialByOrderNumber: [],
  sellProductsInStock: [],
  sellProductsInStockPDF: [],
  totalStockGroupByLocation: [],
  sellableProductsCurrentPage: [],
  sellableProductsTotalItems: [],
  sellableProductsTotalPages: [],
  currentStockAmountByMaterialId: [],
  itemsInPendingOrTransit: [],
  currentStockAmountByLocation: [],
  isCodePersisted: false,
  deliveryTrackingArticles: [],
  totalItemsArticlesTracking: 0,
  totalPagesArticlesTracking: 1,
  articleByBarcode: [],
  sellPriceByVariant: 0,
  sellPriceByBarcode: 0,
  merchandisePagination: [],
  totalPagesMerchandise: 0,
  totalItemsMerchandise: 0,
  locationsByStoreId: [],
};

const getters = {
  getImages: (state) => {
    return state.images;
  },
  getSellPriceByVariant: (state) => {
    return state.sellPriceByVariant
  },
  getTotalItemsMerchandise: (state) => {
    return state.totalItemsMerchandise
  },
  getLocationsByStoreId: (state) => {
    return state.locationsByStoreId
  },
  getTotalPagesMerchandise: (state) => {
    return state.totalPagesMerchandise
  },
  getMerchandisePagination: (state) => {
    return state.merchandisePagination
  },
  getSellPriceByBarcode: (state) => {
    return state.sellPriceByBarcode
  },
  getOrderItemStates: (state) => {
    return state.orderItemStates
  },
  getMaterialItemStates: (state) => {
    return state.materialItemStates
  },
  getDeliveryTrackingArticles: (state) => {
    return state.deliveryTrackingArticles
  },
  getArticleByBarcode: (state) => {
    return state.articleByBarcode
  },
  getTotalItemsTrackingArticles: (state) => {
    return state.totalItemsArticlesTracking
  },
  getTotalPagesArticlesTracking: (state) => {
    return state.totalPagesArticlesTracking
  },
  getIsCodePersisted: (state) => {
    return state.isCodePersisted
  },
  getProductInStockStates: (state) => {
    return state.productInStockStates
  },
  getImagesProduct: (state) => {
    return state.imagesProduct;
  },
  getArticleNumberNameLike: (state) => {
    return state.aricleNumberNameLike
  },
  getSellingReport: (state) => {
    return state.sellingReport
  },
  getArticleReport: (state) => {
    return state.articleReport
  },
  getimagesProductionManagement: (state) => {
    return state.imagesProductionManagement;
  },
  getimagesOrder: (state) => {
    return state.imagesOrder;
  },
  getCustomOrders: (state) => {
    return state.customOrders;
  },
  getMaterialsVByMeter: (state) => {
    return state.materialsVByMeter;
  },
  getMaterialsVByPackage: (state) => {
    return state.materialsVByPackage;
  },
  getMaterialsVBySet: (state) => {
    return state.materialsVBySet;
  },
  getMaterialsVByPair: (state) => {
    return state.materialsVByPair;
  },
  getMaterialsVByVerse: (state) => {
    return state.materialsVByVerse;
  },
  getMaterialsVByVMeter_Square: (state) => {
    return state.materialsVByMeter_Square;
  },
  getMaterialsVByPart: (state) => {
    return state.materialsVByPart;
  },
  getCurrentPageForCustomOrders: (state) => {
    return state.currentPage;
  },
  getOrdersGrouppedByState: (state) => {
    return state.ordersGrouppedByState;
  },
  getOrdersGrouppedByStateDashboard: (state) => {
    state.ordersGrouppedByStateDashboard.unshift('ALL')
    return state.ordersGrouppedByStateDashboard;
  },
  getCurrentPageForMVM: (state) => {
    return state.currentPageForMVM;
  },
  getTotalPagesForMVM: (state) => {
    return state.totalPagesForMVM;
  },
  getCurrentPageForMVP: (state) => {
    return state.currentPageForMVP;
  },
  getTotalPagesForMVP: (state) => {
    return state.totalPagesForMVP;
  },
  getSizeTypes: (state) => {
    return state.sizeTypes;
  },
  getProductInStockTrackAndTrace: (state) => {
    return state.productInStockTrackAndTrace
  },
  getSellingReportPDF: (state) => {
    return state.sellingReportPDF
  },
  getBodyMeasurement: (state) => {
    return state.bodyMeasurement
  },
  getCostOfGoodsSoldPDF: (state) => {
    return state.costOfGoodsSoldPDF
  },
  getTotalPagesCogs: (state) => {
    return state.totalPagesCogs
  },
  getTotalItemsCogs: (state) => {
    return state.totalItemsCogs
  },
  getCurrentPageCogs: (state) => {
    return state.currentPageCogs
  },
  getDressCategory: (state) => {
    return state.dressCategory
  },
  getProductSize: (state) => {
    return state.productSize
  },
  getDressCategoryByName: (state) => {
    return state.dressCategoryByName
  },
  getMaterialByOrderNumber: (state) => {
    return state.materialByOrderNumber
  },
  getProductStockSell: (state) => {
    return state.sellProductsInStock
  },
  getProductStockSellPDF: (state) => {
    return state.sellProductsInStockPDF
  },
  getTotalStockGroupByLocation: (state) => {
    return state.totalStockGroupByLocation
  },
  getSellableProductsCurrentPage: (state) => {
    return state.sellableProductsCurrentPage
  },
  getSellableProductsTotalItems: (state) => {
    return state.sellableProductsTotalItems
  },
  getSellableProductsTotalPages: (state) => {
    return state.sellableProductsTotalPages
  },
  getCurrentStockAmountByMaterialId: (state) => {
    return state.currentStockAmountByMaterialId
  },
  getItemsInPendingOrTransit: (state) => {
    return state.itemsInPendingOrTransit
  },
  getCurrentStockAmountByLocation: (state) => {
    return state.currentStockAmountByLocation
  },
};

const mutations = {
  SET_STATUS(state, payload) {
    state.status = payload;
  },
  SET_CURRENTPAGE(state, payload) {
    state.currentPage = payload;
  },
  SET_CURRENTPAGE_FOR_MVM(state, payload) {
    state.currentPageForMVM = payload;
  },
  SET_LOCATIONS_BY_STOREID(state, latest) {
    state.locationsByStoreId = latest
  },
  SET_DELIVERY_TRACKING_ARTICLES(state, latest) {
    state.deliveryTrackingArticles = latest
  },
  SET_SELL_PRICE_BY_VARIANT(state, latest) {
    state.sellPriceByVariant = latest
  },
  SET_MERCHANDISE_PAGINATION(state, latest) {
    state.merchandisePagination = latest
  },
  SET_TOTALPAGES_MERCHANDISE(state, payload) {
    state.totalPagesMerchandise = payload;
  },
  SET_TOTALITEMS_MERCHANDISE(state, payload) {
    state.totalItemsMerchandise = payload;
  },
  SET_SELL_PRICE_BY_BARCODE(state, latest) {
    state.sellPriceByBarcode = latest
  },
  SET_DELIVERY_TRACKING_ARTICLES_PAGES(state, latest) {
    state.totalPagesArticlesTracking = latest
  },
  SET_DELIVERY_TRACKING_ARTICLES_ITEMS(state, latest) {
    state.totalItemsArticlesTracking = latest
  },
  SET_ARTICLE_BY_BARCODE(state, latest) {
    state.articleByBarcode = latest
  },
  SET_TOTALPAGES_FOR_MVM(state, payload) {
    state.totalPagesForMVM = payload;
  },
  SET_CURRENTPAGE_FOR_MVP(state, payload) {
    state.currentPageForMVP = payload;
  },
  SET_TOTALPAGES_FOR_MVP(state, payload) {
    state.totalPagesForMVP = payload;
  },
  SET_CUSTOM_ORDERS(state, latest) {
    state.customOrders = latest;
  },
  SET_ORDERS_GROUPPED(state, latest) {
    state.ordersGrouppedByState = latest;
  },
  SET_ORDER_ITEM_STATES(state, latest) {
    state.orderItemStates = latest;
  },
  SET_MATERIAL_ITEM_STATES(state, latest) {
    state.materialItemStates = latest;
  },
  SET_PRODUCT_IN_STOCK_STATES(state, latest) {
    state.productInStockStates = latest;
  },
  SET_IS_CODE_PERSISTED(state, latest) {
    state.isCodePersisted = latest
  },
  SET_MATERIALS_BY_METER(state, latest) {
    state.materialsVByMeter = latest;
  },
  SET_MATERIALS_BY_PACKAGE(state, latest) {
    state.materialsVByPackage = latest;
  },
  SET_MATERIALS_BY_SET(state, latest) {
    state.materialsVBySet = latest;
  },
  SET_MATERIALS_BY_PAIR(state, latest) {
    state.materialsVByPair = latest;
  },
  SET_MATERIALS_BY_BEADED_STRAND(state, latest) {
    state.materialsVByVerse = latest;
  },
  SET_MATERIALS_BY_METER_SQUARE(state, latest) {
    state.materialsVByMeter_Square = latest
  },
  SET_ARTICLE_NUMBER_NAMELIKE(state, latest) {
    state.aricleNumberNameLike = latest
  },
  SET_MATERIALS_BY_PART(state, latest) {
    state.materialsVByPart = latest;
  },
  SET_SELLING_REPORT(state, latest) {
    state.sellingReport = latest
  },
  SET_ARTICLE_REPORT(state, latest) {
    state.articleReport = latest
  },
  SET_IMAGES(state, latest) {
    state.images = latest;
  },
  SET_IMAGES_PRODUCT(state, latest) {
    state.imagesProduct = latest;
  },
  SET_IMAGES_PRODUCTION_MANAGEMENT(state, latest) {
    state.imagesProductionManagement = latest;
  },
  SET_IMAGES_ORDER(state, latest) {
    state.imagesOrder = latest;
  },
  SET_STATES_DASHBOARD(state, latest) {
    state.ordersGrouppedByStateDashboard = latest
  },
  SET_SIZE_TYPES(state, latest) {
    state.sizeTypes = latest
  },
  SET_PRODUCT_IN_STOCK_TRACK_AND_TRACE(state, latest) {
    state.productInStockTrackAndTrace = latest
  },
  SET_SELLING_REPORT_PDF(state, latest) {
    state.sellingReportPDF = latest
  },
  SET_BODY_MEASUREMENT(state, latest) {
    state.bodyMeasurement = latest
  },
  SET_COST_OF_GOODS_SOLD_PDF(state, latest) {
    state.costOfGoodsSoldPDF = latest
  },
  SET_TOTAL_ITEMS_COGS(state, latest) {
    state.totalItemsCogs = latest
  },
  SET_TOTAL_PAGES_COGS(state, latest) {
    state.totalPagesCogs = latest
  },
  SET_CURRENT_PAGE_COGS(state, latest) {
    state.currentPageCogs = latest
  },
  SET_DRESS_CATEGORY(state, latest) {
    state.dressCategory = latest
  },
  SET_PRODUCT_SIZE(state, latest) {
    state.productSize = latest
  },
  SET_DRESS_CATEGORY_BY_NAME(state, latest) {
    state.dressCategoryByName = latest
  },
  SET_MATERIAL_BY_ORDER_NUMBER(state, latest) {
    state.materialByOrderNumber = latest
  },
  SET_SELLABLE_PRODUCTS(state, latest) {
    state.sellProductsInStock = latest
  },
  SET_SELLABLE_PRODUCTS_PDF(state, latest) {
    state.sellProductsInStockPDF = latest
  },
  SET_TOTAL_STOCK_GROUP_LOCATION(state, latest) {
    state.totalStockGroupByLocation = latest
  },
  SET_SELLABLE_PRODUCTS_CURRENT_PAGE(state, latest) {
    state.sellableProductsCurrentPage = latest
  },
  SET_SELLABLE_PRODUCTS_TOTAL_ITEMS(state, latest) {
    state.sellableProductsTotalItems = latest
  },
  SET_SELLABLE_PRODUCTS_TOTAL_PAGES(state, latest) {
    state.sellableProductsTotalPages = latest
  },
  SET_CURRENT_STOCK_AMOUNT_BY_MATERIALID(state, latest) {
    state.currentStockAmountByMaterialId = latest
  },
  SET_ITEMS_IN_PENDING_OR_TRANSIT(state, latest) {
    state.itemsInPendingOrTransit = latest
  },
  SET_CURRENT_STOCK_AMOUNT_BY_LOCATION(state, latest) {
    state.currentStockAmountByLocation = Array.isArray(latest) ? latest : [latest];
  },
};

const actions = {
  async getInventoryProductsByColorId({ commit }, id) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/suppliers`, id);
      commit('SET_CUSTOM_ORDERS', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadPriceByVariant({ commit }, { materialVariantId }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/sell_price_by_variantID`, {
        params: {
          materialVariantId,
        },
      });
      commit('SET_SELL_PRICE_BY_VARIANT', response.data);
      return response.data;
    } catch (error) {
      commit('SET_IS_LOADING', true)
      return 0
    }
  },
  async loadPriceByBarcode({ commit }, { barcodeLike }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/product_sell_price_by_barcode`, {
        params: {
          barcodeLike,
        },
      });
      commit('SET_SELL_PRICE_BY_BARCODE', response.data);
      return response.data;
    } catch (error) {
      commit('SET_IS_LOADING', true)
      return 0;
    }
  },
  async getCurrentStockAndLocationByVariantId({ commit }, { materialVariantId }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/current_stock_amount_and_location_by_variantId?materialVariantId=${materialVariantId}`);
      console.log('API Response:', response.data);
      commit('SET_CURRENT_STOCK_AMOUNT_BY_LOCATION', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async getPendingOrTransitItems({ commit }, { articleNumber }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/items_in_pending_or_transit?articleNumber=${articleNumber}`);
      commit('SET_ITEMS_IN_PENDING_OR_TRANSIT', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async currentStockAmountMaterialId({ commit }, materialId) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/current_stock_amount_by_materialid?materialId=${materialId}`);
      commit('SET_CURRENT_STOCK_AMOUNT_BY_MATERIALID', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async materialInvoiceByOrderNumber({ commit }, { orderNumber }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/material_invoice_by_order_number?orderNumber=${orderNumber}`);
      commit('SET_MATERIAL_BY_ORDER_NUMBER', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async getDressByCategoryName({ commit }, { name }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/mainDressCategoriesNameLike?name=${name}`);
      commit('SET_DRESS_CATEGORY_BY_NAME', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadDeliveryTrackingArticles({ commit }, {
    inventoryProductId, startDate, endDate, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain4}/delivery_tracking_for_articles`, {
        params: {
          inventoryProductId,
          startDate,
          endDate,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_DELIVERY_TRACKING_ARTICLES', response.data.items);
      commit('SET_DELIVERY_TRACKING_ARTICLES_ITEMS', response.data.totalItems);
      commit('SET_DELIVERY_TRACKING_ARTICLES_PAGES', response.data.totalPages);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadArticleByBarcode({ commit }, {
    barcodeLike,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/article_by_barcode_like`, {
        params: {
          barcodeLike,
        },
      });
      commit('SET_ARTICLE_BY_BARCODE', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async resetLoadBarcode({ commit }) {
    commit('SET_ARTICLE_BY_BARCODE', []);
  },
  async dressCategories({ commit }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/dress_categories`);
      commit('SET_DRESS_CATEGORY', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async productSizes({ commit }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/sizes`);
      commit('SET_PRODUCT_SIZE', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async calibrationBodyMeasurement({ commit }, inventoryProductId) {
    console.log('InventoryId:', inventoryProductId.inventoryProductId);
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/calibration_body_measurements?inventoryProductId=${inventoryProductId}`);
      commit('SET_BODY_MEASUREMENT', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async orderItemStates({ commit }) {
    commit('SET_IS_LOADING', false);
    if (state.orderItemStates.length > 0) {
      commit('SET_IS_LOADING', true);
      return;
    }
    try {
      const response = await axios.get(`${domain2}/order_item_states`);
      commit('SET_ORDER_ITEM_STATES', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async materialItemStates({ commit }) {
    commit('SET_IS_LOADING', false);
    if (state.materialItemStates.length > 0) {
      commit('SET_IS_LOADING', true);
      return;
    }
    try {
      const response = await axios.get(`${domain2}/material_order_item_states`);
      commit('SET_MATERIAL_ITEM_STATES', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadProductInStockStates({ commit }) {
    commit('SET_IS_LOADING', false);
    if (state.productInStockStates.length > 0) {
      commit('SET_IS_LOADING', true);
      return;
    }
    try {
      const response = await axios.get(`${domain2}/productInStock_states`);
      commit('SET_PRODUCT_IN_STOCK_STATES', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadCodePersisted({ commit }, { code }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/is_article_code_persisted`, {
        params: {
          code,
        },
      });
      commit('SET_IS_CODE_PERSISTED', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async resetCodePersisted({ commit }) {
    commit('SET_IS_CODE_PERSISTED', false);
  },
  async getImagesByMainDressCategoryColorId({ commit }, id) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/getFileByProductId?mainDressCategoryColorId=${id}`);
      commit('SET_IMAGES', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async getImagesByMaterialVariantId({ commit }, id) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/getMaterialVariantPhotobyMaterialVariantId?materialVariantId=${id}`);
      commit('SET_IMAGES_PRODUCT', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async getFileByArticleNumber({ commit }, articleNumber) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/getFileByArticleNumber?articleNumber=${articleNumber}`);
      commit('SET_IMAGES_PRODUCTION_MANAGEMENT', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  resetFileByArticleNumber({ commit }) {
    commit('SET_IMAGES_PRODUCTION_MANAGEMENT', []);
  },
  async getFileByOrderNumber({ commit }, { orderId }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/get_file_by_orderId`, {
        params: {
          orderId,
        },
      });
      commit('SET_IMAGES_ORDER', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async deletePhoto({ commit }, { Id, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.delete(`${domain3}/deletePhotoById?Id=${Id}`);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Files by product deleted succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid files data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async deleteImage({ commit }, { id, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.delete(`${domain}/deleteFileByProductId/{id}?fileDataId=${id}`);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Files by product deleted succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid files data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async deleteFile({ commit }, { fileId, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.delete(`${domain2}/delete_file_by_Id`, {
        params: {
          fileId,
        },
      });
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Files by product deleted succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid files data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async deleteImageMaterialVariant({ commit }, { id, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.delete(`${domain}/deleteMaterialVariantPhotobyMaterialVariantId?materialVariantPhotoId=${id}`);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Material variant photo by material variant id deleted succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid material variant phot data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async addCalibrationBodyMeasurement({ commit }, { inventoryId, object, successCallback }) {
    // const objToJson = JSON.stringify(object);
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/add_calibration_body_measurements?inventoryId=${inventoryId}`, object);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Calibration measurement updated succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid calibration data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async loadLocationsByStoreId({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.post(`${domain}/location_names_by_storeIds`, object);
      commit('SET_LOCATIONS_BY_STOREID', response.data);
      commit('SET_STATUS', true);
      successCallback();
    } catch (error) {
      console.log('error', error)
      commit('SET_IS_LOADING', true);
    }
    commit('SET_IS_LOADING', true);
  },
  async addProductCategory({ commit }, { object, successCallback }) {
    // const objToJson = JSON.stringify(object);
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/add_lov_dress_category`, object);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Category added succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid category data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async createOrderAvailable({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.post(`${domain2}/create_order_from_available_stock`, object);
      commit('SET_STATUS', true);
      successCallback(response.data);
      Swal.fire({
        icon: 'success',
        title: 'Created order succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async addProductLovSize({ commit }, { object, successCallback }) {
    // const objToJson = JSON.stringify(object);
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/add_size_data`, object);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Size added succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid size data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async editCategory({ commit }, {
    object, successCallback,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/edit_lov_dress_category?id=${object.id}&name=${object.name}`, object);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Category edited succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
    // Handle error response
    // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid category data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async editLuvSize({ commit }, {
    object, successCallback,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/edit_size?id=${object.id}&name=${object.name}`, object);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Size edited succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
    // Handle error response
    // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid size data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async updatePatternTime({ commit }, {
    inventoryId, newPatternTime, object, successCallback,
  }) {
    // const objToJson = JSON.stringify(object);
    console.log(inventoryId)
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/update_pattern_time?inventoryId=${inventoryId}&newPatternTime=${newPatternTime}`, object);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Pattern time updated succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid pattern time data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async updateSewingTime({ commit }, {
    inventoryId, newSewingTime, object, successCallback,
  }) {
    // const objToJson = JSON.stringify(object);
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/update_sewing_time?inventoryId=${inventoryId}&newSewingTime=${newSewingTime}`, object);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Sewing time updated succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid sewing time data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async updateEmbroideryTime({ commit }, {
    inventoryId, newEmbroideryTime, object, successCallback,
  }) {
    // const objToJson = JSON.stringify(object);
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/update_embroidery_time?inventoryId=${inventoryId}&newEmbroideryTime=${newEmbroideryTime}`, object);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Embroidery time updated succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid embroidery time data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async addInventoryProduct({ commit }, { object, successCallback }) {
    // const objToJson = JSON.stringify(object);
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/addSupplier`, object);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Supplier added succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid supplier data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async loadOrdersGrouppedByState({ commit }, { year }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/cockpitWindowItemOverview?year=${year}`);
      commit('SET_ORDERS_GROUPPED', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadSizeTypes({ commit }) {
    commit('SET_IS_LOADING', false);
    if (state.sizeTypes.length > 0) {
      commit('SET_IS_LOADING', true);
      return;
    }
    try {
      const response = await axios.get(`${domain}/getSizeData`);
      commit('SET_SIZE_TYPES', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  // async loadOrdersGrouppedByStateDashboard({ commit }) {
  //   commit('SET_IS_LOADING', false);
  //   if (state.ordersGrouppedByState.length > 0) {
  //     commit('SET_IS_LOADING', true);
  //     return;
  //   }
  //   try {
  //     const response = await axios.get(`${domain2}/cockpitOrderItemOverview`);
  //     commit('SET_STATES_DASHBOARD', response.data);
  //   } catch (error) {
  //     commit('SET_IS_LOADING', true)
  //   }
  //   commit('SET_IS_LOADING', true);
  // },
  async getMaterialVariantsToMDCPagination({ commit }, { pageNumber, pageSize, measurement } = {}) {
    commit('SET_STATUS', true);
    const response = await axios.get(
      `${domain}/getMaterialVariantsToMDCPagination?measurementType=${measurement}`,
      {
        params: {
          pageNumber,
          pageSize,
        },
      },
    );
    if (measurement === 'Meter') {
      commit('SET_CURRENTPAGE_FOR_MVM', response.data.currentPage);
      commit('SET_TOTALPAGES_FOR_MVM', response.data.totalPages);
      commit('SET_MATERIALS_BY_METER', response.data.items);
    } else if (measurement === 'Package') {
      commit('SET_CURRENTPAGE_FOR_MVM', response.data.currentPage);
      commit('SET_TOTALPAGES_FOR_MVM', response.data.totalPages);
      commit('SET_MATERIALS_BY_PACKAGE', response.data.items);
    } else if (measurement === 'Set') {
      commit('SET_CURRENTPAGE_FOR_MVM', response.data.currentPage);
      commit('SET_TOTALPAGES_FOR_MVM', response.data.totalPages);
      commit('SET_MATERIALS_BY_SET', response.data.items);
    } else if (measurement === 'Pair') {
      commit('SET_CURRENTPAGE_FOR_MVM', response.data.currentPage);
      commit('SET_TOTALPAGES_FOR_MVM', response.data.totalPages);
      commit('SET_MATERIALS_BY_PAIR', response.data.items);
    } else if (measurement === 'Verse') {
      commit('SET_CURRENTPAGE_FOR_MVM', response.data.currentPage);
      commit('SET_TOTALPAGES_FOR_MVM', response.data.totalPages);
      commit('SET_MATERIALS_BY_BEADED_STRAND', response.data.items);
    } else if (measurement === 'Meter_Square') {
      commit('SET_CURRENTPAGE_FOR_MVM', response.data.currentPage);
      commit('SET_TOTALPAGES_FOR_MVM', response.data.totalPages);
      commit('SET_MATERIALS_BY_METER_SQUARE', response.data.items);
    } else {
      commit('SET_CURRENTPAGE_FOR_MVP', response.data.currentPage);
      commit('SET_TOTALPAGES_FOR_MVP', response.data.totalPages);
      commit('SET_MATERIALS_BY_PART', response.data.items);
    }

    return response;
  },
  async productInStock_trackAndTrace({ commit }, { productInStockId, pageNumber, pageSize }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/productInStock_trackAndTrace`, {
        params: {
          productInStockId,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_PRODUCT_IN_STOCK_TRACK_AND_TRACE', response.data.items);
      commit('SET_TOTALPAGES_FOR_MVM', response.data.totalPages);
      commit('SET_MATERIALS_BY_METER', response.data.items);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async totalStockGroupedByLocation({ commit }, { inventoryProductId }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain4}/sellable_product_by_article_number`, {
        params: {
          inventoryProductId,
        },
      });
      commit('SET_TOTAL_STOCK_GROUP_LOCATION', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async resetTotalStockGroupedByLocation({ commit }) {
    commit('SET_TOTAL_STOCK_GROUP_LOCATION', []);
  },
  async loadMerchandisePagination({ commit }, {
    storeId, inventoryProductId, applyStrickMerchandiseFilter, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain4}/merchandise_pagination`, {
        params: {
          storeId,
          inventoryProductId,
          applyStrickMerchandiseFilter,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_MERCHANDISE_PAGINATION', response.data.items);
      commit('SET_TOTALPAGES_MERCHANDISE', response.data.totalPages);
      commit('SET_TOTALITEMS_MERCHANDISE', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async sellingReport({ commit }, {
    startDate, endDate, pageNumber, pageSize, orderNumber, orderId, clientId,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/sellingReporting_minimalPagination`, {
        params: {
          startDate,
          endDate,
          pageNumber,
          pageSize,
          orderNumber,
          orderId,
          clientId,
        },
      });
      commit('SET_SELLING_REPORT', response.data.items);
      commit('SET_CURRENTPAGE_FOR_MVM', response.data.currentPage);
      commit('SET_TOTALPAGES_FOR_MVM', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async articleReport({ commit }, {
    year, articleNumber,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/sellingReporting_ByArticleNumber`, {
        params: {
          year,
          articleNumber,
        },
      });
      commit('SET_ARTICLE_REPORT', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async articleNumberNameLike({ commit }, articleNumber) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/sellingReporting_articleNumberLike?articleNumber=${articleNumber}`)
      commit('SET_ARTICLE_NUMBER_NAMELIKE', response.data)
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadSellingReportPDF({ commit }, {
    year, month, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/sellingReporting_minimalPagination`, {
        params: {
          year,
          month,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_SELLING_REPORT_PDF', response.data);
      commit('SET_CURRENTPAGE_FOR_MVM', response.data.currentPage);
      commit('SET_TOTALPAGES_FOR_MVM', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadCostGoodsSoldPDF({ commit }, {
    from, to, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/calculate_cost_sold_goods`, {
        params: {
          from,
          to,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_COST_OF_GOODS_SOLD_PDF', response.data);
      commit('SET_TOTAL_PAGES_COGS', response.data.totalPages);
      commit('SET_TOTAL_ITEMS_COGS', response.data.totalItems);
      commit('SET_CURRENT_PAGE_COGS', response.data.currentPage);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async sellableProducts({ commit }, {
    searchFilter, inventoryProductId, locationId, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain4}/sellable_products`, {
        params: {
          searchFilter,
          inventoryProductId,
          locationId,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_SELLABLE_PRODUCTS', response.data.items);
      commit('SET_SELLABLE_PRODUCTS_TOTAL_PAGES', response.data.totalPages);
      commit('SET_SELLABLE_PRODUCTS_TOTAL_ITEMS', response.data.totalItems);
      commit('SET_SELLABLE_PRODUCTS_CURRENT_PAGE', response.data.currentPage);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async sellableProductsPDF({ commit }, {
    searchFilter, inventoryProductId, locationId, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain4}/sellable_products`, {
        params: {
          searchFilter,
          inventoryProductId,
          locationId,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_SELLABLE_PRODUCTS_PDF', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
