<template>
  <div
    v-if="canShowSelect"
    style="width:20%"
  >
    <b-form-group
      :label="$t('Filiale')"
      style="width:100%"
    >
      <vue-select
        v-model="localFiliale"
        :options="filteredStores"
        label="storeName"
        :clearable="false"
        :placeholder="$t('SelectFiliale')"
        style="width:100%"
      />
    </b-form-group>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    filiale: {
      type: Object,
      default: () => {
        return {
          storeId: null,
          storeName: 'No filiale',
          storeGLN: null,
        }
      },
    },
  },
  data() {
    return {
      localFiliale: { ...this.filiale },
    };
  },
  computed: {
    ...mapGetters(['getAllStoresDropdown', 'getLoggedInUser']),
    filteredStores() {
      const filteredStores = this.getAllStoresDropdown.filter((store) => {
        return store.storeName !== 'all' && store.storeName !== 'unassigned'
      });

      const allOption = { storeId: null, storeName: 'No filiale', storeGLN: null };
      const unassigned = { storeId: '00000000-0000-0000-0000-000000000000', storeName: 'Unassigned', storeGLN: null };
      return [allOption, unassigned, ...filteredStores];
    },
    canShowSelect() {
      const user = this.getLoggedInUser;
      return (user.role === 'CEO' || user.role === 'Admin') || (user.storeId === null || user.storeId === '00000000-0000-0000-0000-000000000000');
    },
  },
  watch: {
    localFiliale: {
      handler(newValue) {
        this.$emit('updateFiliale', newValue);
      },
      deep: true,
    },
  },
  mounted() {
    this.loadAllStoresByDropdown({
      amountOfRecords: 100,
    })
      .then(() => {
        this.updateFiliale();
      })
      .catch((error) => {
        console.error('Error in loading stores or updating Filiale:', error);
      });
  },
  methods: {
    ...mapActions(['loadAllStoresByDropdown']),
    updateFiliale() {
      const storeId = this.getLoggedInUser.storeId?.toUpperCase();

      if (storeId) {
        const store = this.filteredStores.find((stores) => { return stores.storeId === storeId });
        if (store) {
          this.localFiliale = {
            storeId: store.storeId,
            storeName: store.storeName,
            storeGLN: store.storeGLN,
          };
        } else {
          this.localFiliale = { storeId: null, storeName: 'No Filiale', storeGLN: null };
        }
      } else {
        this.localFiliale = { storeId: null, storeName: 'No Filiale', storeGLN: null };
      }
    },
  },
};
</script>

  <style scoped lang="scss">
  </style>
